@import "../../../styles.module.scss";
@import "../../../../../styles/mixins";

.postsContainerWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  position: relative;
  margin: 0 auto;
  @include setContentWidth;

  &.call {
    grid-template-columns: repeat(3, 1fr);
    @include setContentWidth;
    @include for-phone-only {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include for-phone-only {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .postCardWrapper {
    cursor: pointer;
    width: auto;
  }

  .bottomSrollDiv {
    position: absolute;
    bottom: 50vh;
    @include for-phone-only {
      bottom: 80vh;
    }
  }
}

.chat {
  @extend .postsContainerWrapper;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;

  @include for-phone-only {
    @include flex-column;
    gap: 8px;
    margin: auto;
  }
}
