@import "../../../../../styles/mixins.scss";

.ListCard {
  width: 360px;
  height: fit-content;
  box-shadow: 0px 1px 10px #3e1a501a;
  border-radius: 16px;
  @include flex-wrap;
  gap: 12px 16px;
  color: var(--primary);
  padding: 24px;
  cursor: pointer;

  .leftContent {
    width: 30%;
    @include flex-column;
    justify-content: space-between;
    align-items: center;

    .imageWrapper {
      width: 83px;
      height: 83px;
      border-radius: 50%;
      @include flex-column;
      align-items: center;

      .profileImage {
        width: 100%;
        min-height: 100%;
        border-radius: 50%;
      }

      .status {
        width: 54px;
        text-align: center;
        padding: 3.5px 0px;
        color: var(--terinary);
        background: #2bc379;
        border: 2px solid #ffffff;
        position: relative;
        bottom: 15px;
        border-radius: 24px;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .stars {
      display: flex;
      margin-top: 18px;
    }

    .order {
      display: none;
    }
  }

  .rightContent {
    width: calc(70% - 16px);
    @include flex-column;
    justify-content: space-between;

    .content {
      @include flex-column;
      gap: 8px;

      h1 {
        font-weight: 600;
        font-size: 18px;
      }

      .categoryWrapper {
        width: 100%;
        overflow-x: scroll;
        display: flex;
        gap: 3px;

        .category {
          width: fit-content;
          background: #ff78014b;
          color: #2a0649;
          padding: 0px 8px;
          text-transform: uppercase;
          border-radius: 40px;
          white-space: pre;
        }
      }

      ul {
        overflow: hidden;
        @include flex-wrap;
        gap: 0px 24px;
        li {
          list-style-type: disc;
          &:first-child {
            list-style-type: none;
          }
        }
      }

      li,
      p,
      .button,
      .category {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }

  .bottomWrapper {
    width: 100%;
    display: flex;
    gap: 14px;

    .price {
      width: 100%;
      padding: 11px;
      text-align: center;
      background: #2bc37a4d;
      color: #2a0649;
      border-radius: 30px;
      font-size: 8px;
      font-weight: 500;
      line-height: 12px;
      white-space: nowrap;
      max-height: 40px;
      @include justify-align-center;
      flex-direction: column;
      b {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
      }
      .price_cart {
        .line {
          display: none;
        }
        &.old_price {
          font-size: 9px;
          position: relative;
          b {
            font-size: 11px;
            line-height: 15px;
          }
          .line {
            display: block;
            position: absolute;
            top: 7px;
            left: -5px;
            border-top: 1px solid rgba(42, 6, 73, 1);
            transform: rotate(12deg);
            width: 120%;
          }
        }
      }

      .free_price_cart {
        b {
          font-size: 16px;
          line-height: 14px;
        }
      }
    }

    .button {
      min-width: 202px;
      height: 40px;
      padding: 9px 20px 9px 7px;
      border-radius: 30px;
      color: var(--terinary);
      background: linear-gradient(135deg, #ab5eee 0%, #4f0995 100%);
      @include justify-align-center;
      font-weight: 500;
      align-self: flex-end;

      img {
        &:first-of-type {
          display: block;
        }
        &:last-of-type {
          display: none;
        }
      }
    }
  }

  @include for-phone-only {
    width: 100%;
    padding: 16px;
    gap: 10px 18px;
    position: relative;

    .leftContent {
      width: 20%;
      gap: 9px;

      .imageWrapper {
        width: 67px;
        height: 67px;
        border: 2px solid #2bc379;

        .status {
          font-size: 10px;
          font-weight: 500;
          line-height: 10px;
          padding: 5px 4.5px;
          bottom: 10px;
        }
      }

      .stars {
        margin-top: 9px;
        img {
          width: 13px;
          height: 13px;
        }
      }

      .order {
        display: block;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        position: relative;
        bottom: 5px;
      }
    }

    .rightContent {
      h1 {
        font-size: 14px;
      }
      .content {
        gap: 7px;
        .categoryWrapper {
          gap: 4px;

          .category {
            padding: 2px 5px;
            font-size: 10px;
            line-height: 15px;
          }
        }

        ul {
          gap: 18px;
        }

        li,
        p,
        .category {
          font-size: 10px;
          line-height: 15px;
        }

        p {
          position: absolute;
          right: 16px;
          border-radius: 40px;
          background: #8272902f;
          padding: 2px 6px;
        }
      }
    }
    .bottomWrapper {
      gap: 8px;
      width: 70%;
      position: absolute;
      bottom: 16px;
      right: 16px;

      .price {
        min-width: 69px;
        padding: 8px;
      }

      .button {
        min-width: 140px;
        height: 34px;
        background: none;
        border: 1px solid #4f0995;
        color: #4f0995;
        gap: 4px;
        padding: 6.5px;

        img {
          &:first-of-type {
            display: none;
          }
          &:last-of-type {
            display: inline-block;
          }
        }
      }
    }
  }
}
