@import "../../../modules/Astro/styles.module";
.each_astrologer {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  max-height: 515px;
  height: 515px;
  color: black;
  background: white;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  cursor: pointer;

  @include for-xl-only {
    height: 436px;
  }

  @include for-phone-only {
    padding: 1rem;
    min-width: 243px;
    height: 315px;
    //min-height: 239px;
  }

  .profile_cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25%;
    padding-top: 2rem;

    .divider {
      height: 2px;
      width: 100%;
      margin: 1rem 0;
      //position: absolute;
      background: rgba(210, 210, 210, 1);
    }
    .profile_img_cont {
      position: relative;
      .profile_img {
        width: 134px;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 50%;

        @include for-xl-only {
          width: 106px;
        }
        @include for-phone-only {
          width: 60px;
        }
      }

      .profile_status {
        right: 4%;
        top: 4%;
        position: absolute;
        width: 26px;
        border-radius: 50%;
        aspect-ratio: 1;
        background-color: rgba(19, 193, 153, 1);
        border: 4px solid white;

        @include for-xl-only {
          right: 3%;
          top: 3%;
          width: 18px;
          border: 3px solid white;
        }
        @include for-phone-only {
          width: 10px;
        }
      }
    }
    .profile_tags {
      flex: 1;
      .tags {
        width: 80%;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        height: 50%;
        div {
          margin: 4px;
          height: 29px;
          border-radius: 60px;
          padding: 3px 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(234, 234, 234, 1);
          font-weight: 500;
          color: rgba(69, 69, 69, 1);
          @include for-phone-only {
            font-size: 8px;
            height: auto;
          }

          @include for-xl-only {
            font-size: 10px;
            margin: 2px;
          }
        }
      }

      .profile_rate {
        line-height: 45px;
        height: 45px;
        margin-left: auto;
        width: fit-content;
        display: flex;
        align-items: center;
        color: white;
        background: rgba(191, 157, 218, 1);
        padding: 0px 11px;
        letter-spacing: 1px;
        border-radius: 60px;
        font-weight: 600;
        font-size: 16px;

        @include for-xl-only {
          font-size: 12px;
          height: 35px;
        }

        @include for-phone-only {
          font-size: 8px;
          height: 19px;
        }

        .rupees {
          height: 0.88rem;
          aspect-ratio: 1;
          object-fit: contain;
          margin-right: 2px;

          @include for-xl-only {
            width: 14px;
          }
          @include for-phone-only {
            height: 10px;
            width: 9px;
            margin-right: 2px;
          }
        }
        .strike_rate {
          position: relative;
          margin-left: 0.5rem;
          @include for-phone-only {
            margin-left: 4px;
          }
        }
        .strike_rate:before {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          right: 0;
          border-top: 2px solid;
          border-color: red;

          -webkit-transform: rotate(5deg);
          -moz-transform: rotate(5deg);
          -ms-transform: rotate(5deg);
          -o-transform: rotate(5deg);
          transform: rotate(5deg);
        }
      }
    }
  }
  .expert_details {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: black;

    .icon {
      width: 18px;
      aspect-ratio: 1;
      margin-right: 1rem;
    }
    .name {
      width: 400px;
      font-weight: 600;
      font-size: 32px; // 32px;
      line-height: 48px;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      //text-overflow: clip;
      //text-overflow: ellipsis ellipsis;
      //text-overflow: ellipsis " [..]";

      @include for-xl-only {
        font-size: 20px;
        line-height: 30px;
      }
      @include for-phone-only {
        font-size: 16px;
      }
    }
    .badge {
      margin-left: 0.5rem;
      @include for-phone-only {
        margin-left: 4px;
        width: 10px;
      }
    }

    .exp {
      font-size: 16px;
      line-height: 10px;
      display: flex;
      align-items: center;
      font-weight: 400;

      @include for-xl-only {
        font-size: 14px;
      }
      @include for-phone-only {
        font-size: 10px;
      }
    }
    .lang {
      text-align: left;
      margin: 1rem 0;
      font-size: 16px;
      line-height: 10px;
      font-weight: 400;
      display: flex;
      align-items: center;

      @include for-xl-only {
        font-size: 14px;
      }

      @include for-phone-only {
        margin: 0.5rem 0;
        font-size: 10px;
      }

      & > .lang_div {
        display: flex;
        align-items: center;
        div {
          margin: 0 0.5rem;
          width: 4px;
          aspect-ratio: 1;
          background: black;
          border-radius: 50%;
        }
      }
      .lang_div:last-child {
        div {
          display: none;
        }
      }
    }
  }

  .stars_cont {
    margin: 1rem 0 0.5rem 0;
    display: flex;
    align-items: center;
    span {
      aspect-ratio: 1;
      font-size: 28px;
      margin-right: 0.5rem;

      @include for-xl-only {
        font-size: 24px;
      }
      @include for-md-only {
        font-size: 15px;
        margin-right: 2px;
      }
    }

    .selected {
      color: #ff6132;
    }
    .not_selected {
      color: rgba(196, 193, 200, 1);
    }
    p {
      color: black;
      letter-spacing: 1px;
    }
  }

  .action_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    button:nth-child(2) {
      background: linear-gradient(
        99.24deg,
        #7166f9 0%,
        #9686fc 51.61%,
        #84c4ff 102.15%
      );
    }

    button:nth-child(1) {
      background: linear-gradient(125.75deg, #ffa767 -8.85%, #eb468b 90.19%);
      cursor: not-allowed;
      opacity: 0.6;
      display: none;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 48%;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 1px;
      font-family: "Poppins", sans-serif;
      color: white;
      outline: none;
      //aspect-ratio: 2.9;
      height: 76px; //76px;
      padding: 11.5px 32px;
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 94px;
      img {
        margin-right: 0.5rem;
        @include for-xl-only {
          width: 24px;
        }
        @include for-phone-only {
          width: 10px;
        }
      }

      @include for-xl-only {
        min-width: 134px;
        height: 58px;
        font-size: 16px;
      }

      @include for-phone-only {
        min-width: 98px;
        height: 32px;
        font-size: 10px;
      }
    }
  }
}
